<template>
  <div>
    <el-dialog :visible.sync="addServiceDialog" width="50%" title="团购" :close-on-click-modal="false">
      <el-form
        :model="form"
        label-width="auto"
        :rules="rules"
        ref="courseForm"
      >
      <div class="flex">
        <div>
          <el-form-item label="团购名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称"></el-input>
          </el-form-item>
          <el-form-item label="类目">
            <el-select
              v-model="form.categories"
              :placeholder="form.categories || '类目'"
              clearable
            >
              <el-option
                v-for="(item, index) in categories_child_list"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
           <el-form-item label="类型" required>
              <el-radio-group v-model="form.type">
                <el-radio label="1">次卡</el-radio>
                <el-radio label="2">月卡</el-radio>
              </el-radio-group>
            </el-form-item>
             <el-form-item label="是否需要线上预约" required>
              <el-radio-group v-model="form.type">
                <el-radio label="true">需要</el-radio>
                <el-radio label="false">不需要</el-radio>
              </el-radio-group>
            </el-form-item>
          <el-form-item label="次/天数">
            <el-input
              v-model="form.count"
              placeholder="次数、天数"
            ></el-input>
          </el-form-item>
          <el-form-item label="成团人数">
            <el-input
              v-model="form.group_count"
              placeholder=""
            ></el-input>
          </el-form-item>
            <el-form-item label="原价">
            <el-input
              v-model="form.origin_price"
              placeholder="划线价"
            ></el-input>
          </el-form-item>
          <el-form-item label="售价">
            <el-input
              v-model="form.price"
              placeholder="售价"
            ></el-input>
          </el-form-item>
            <el-form-item label="结算价">
            <el-input
              v-model="form.settlement_price"
              placeholder="结算价"
            ></el-input>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="有效期/天">
             <el-input
                v-model="form.duration"
                placeholder="有效期"
              ></el-input>天
          </el-form-item>
           <el-form-item label="可用时间">
             <el-input
                v-model="form.duration"
                placeholder="周末、08：00-9：00等"
              ></el-input>
          </el-form-item>
          <el-form-item label="封面" ref="imgUpRef" required>
            <avatar-uploader
              @getImgUrl="v=>form.image = v"
              :url="form.image"
              :clear="if_clear"
            ></avatar-uploader>
          </el-form-item>
          <el-form-item label="详情图片">
            <images-uploader
              @getImgUrl="v => form.introduction_img = v"
              :urls="form.introduction_img"
              :clear="if_clear"
            >
            </images-uploader>
          </el-form-item>
          <el-form-item label="简介">
            <el-input
              type="textarea"
              v-model="form.introduction"
              placeholder="简介"
            >
            </el-input>
          </el-form-item>
        </div>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addServiceDialog = false">取 消</el-button>
         <el-button v-if="form.id"   type="primary" @click="updateForm()">修 改</el-button>
        <el-button v-else type="primary" @click="submitForm()"
          >添 加</el-button
        >
       
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import { rules } from "./addRule";
import { ObjectChangedValue } from "@/js/utils";
export default {
  
  data() {
    return {
      form: {},
      temp: {},
      rules: rules,
      addServiceDialog: false,
      if_clear: true,
      count: 1,
      cityList: [], 
    };
  },

  computed: {
    categories_child_list() {
      return this.$store.state.categories_child_list;
    },
  },
  mounted() {},

  methods: {
  
    open(info) {
       if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
      } else {
        this.form =  this.temp = Object.assign({}, info);
        this.temp = {};
      }
      this.addServiceDialog = true;
      
    },
    updateForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.updateServie();
        }
      });
    },
    submitForm() {
      this.$refs.courseForm.validate((valid) => {
        if (valid) {
          this.addNew();
        }
      });
    },
    addNew() {
      let url = "/shared/groupProduct/insert";
      this.if_clear = false;
      this.form.status = 1
      this.form.sharing = 'share'
      this.$axios.post(url, this.form).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "warning", message: "添加成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    updateServie() {
      let data = ObjectChangedValue(this.temp, this.form);
      data.id = this.form.id;
      this.if_clear = false;
      let url = "/shared/groupProduct/update";
      this.form.deduction_course_num = 1;
      this.$axios.post(url, data).then((res) => {
        if (res.data.code == 0) {
          this.$message({ type: "success", message: "修改成功!" });
          this.addServiceDialog = false;
          this.if_clear = true;
          this.form = {};
          this.temp = {};
          this.$emit("success");
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    }
  },
};
</script>
  
  <style lang="scss" scoped>
</style>